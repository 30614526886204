// @flow
/* -----------------Globals--------------- */
import React from 'react';

/* -----------------Third parties--------------- */
import { I18n } from '@aws-amplify/core';

/* -----------------UI--------------- */
import { withStyles } from '@material-ui/core/styles';

/* -----------------Context--------------- */
import {
  pageVariant,
  PageContext,
} from '@components/common/PageContext';

/* -----------------Assets--------------- */
import ErrorImage404 from '@images/illustrations/404.svg';

/* -----------------Child components--------------- */
import ErrorComponent from '@components/common/ErrorComponent';

/* -----------------Styles--------------- */
const styles = () => ({
  image: {
    width: '100%',
    maxWidth: 400,
  },
});

/* -----------------Type declarations--------------- */
type Classes = {
  image: string,
};

/* -----------------Props--------------- */
type Props = {
  classes: Classes,
};

/* -----------------Component--------------- */
const ErrorPage = (props) => {
  const { classes } = props;
  const title = 'Whoops! Page not found';
  const { setVariant } = React.useContext(PageContext);
  const router = useRouter();

  React.useEffect(() => {
    if (typeof setVariant === 'function') setVariant(pageVariant.static, null);
    if (router.pathname === '/') {
      router.push('/');
    }
  }, [setVariant, router]);
  const renderErrorImage = () => (
    <img
      src={ErrorImage404}
      alt={I18n.get('Page not found')}
      className={classes.image}
    />
  );

  return <ErrorComponent errorImage={renderErrorImage()} title={title} />;
};


export default withStyles(styles)(ErrorPage);
